
import { defineComponent, reactive, toRefs } from 'vue'
import { useRoute } from 'vue-router'

import { url } from '@/service/config'

//import HelloWorld from '@/components/HelloWorld.vue' // @ is an alias to /src

export default defineComponent({
    setup() {
        const route = useRoute().query
        let t = reactive<any>({
            video_url: route.isQn === 'yes' ? route.video_url : url + route.video_url
        })

        return { ...toRefs(t) }
    }
})
